export default [
  {
    name: "furnace",
    input: ["stone", "wood"],
    output: ["stone-block", "coal"],
  },
  {
    name: "sawmill",
    input: ["wood"],
    output: ["wood-stick"],
  },
  {
    name: "blacksmith",
    input: ["iron"],
    output: ["iron-stick"],
  },
];
